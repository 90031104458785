@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&display=swap");
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}
ul {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
}
:root {
  --gradient-blue: #4e7ff1;
  --border-color: #000000;
  --main-color: #5e616f;
  --max-width: 1100px;
}

.nav-bx {
  display: flex;
  width: 100%;
  padding-top: 15px;
  background: #5e616f;
  margin-bottom: 20px;
}

.home-nav-bx {
  display: flex;
  width: 100%;
  /* margin-bottom: 20px; */
  background-color: #000000 !important;
}

.home-nav {
  background-color: #000000 !important;
}

.nav {
  padding: 20px 20px;
  padding-bottom: 0;
  background-color: #5e616f;
  border-bottom: 1px solid #fff;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.hero-contents {
  max-width: var(--max-width);
  margin: 100px auto;
}
.hero-contents h1 {
  font-size: 3rem;
  text-align: start;
  max-width: 600px;
  font-weight: 900;
  color: #fff;
}
.hero-contents h4 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
}
.nav-container {
  max-width: 1280px;
  padding: 0 2rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: 767px) {
    padding: 0 1rem;
  }
}

.nav-container h2 {
  color: white;
  font-size: 25px;
}

.nav-container a > img {
  margin: 0;
  height: 120px;
  background-color: dimgray;
  padding: 1px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.nav-links {
  display: flex;
  align-items: end;
  align-items: center;
  margin-bottom: 0;
}

.responsive-nav-link {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.nav-link {
  text-transform: capitalize;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 20px;
}

.nav-link a {
  text-decoration: none;
}

.nav-link ::before {
  content: "";
  background-color: var(--border-color);
  height: 5px;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  bottom: -3px;

  transform: scaleX(0);
  opacity: 0;
}
.nav-link:hover ::before {
  transform: scaleX(1);
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.nav-link a {
  color: #fff;
}
.active-nav {
  transform: scaleX(1);
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.hrs {
  /* padding-bottom: 20px; */
  margin: 0 20px;
  width: 30px;
}
.top-hr,
.bottom-hr {
  border: 0;
  height: 6px;
  background-color: #fff;
  border-radius: 10px;
  margin: 4px 0;
}
.fixed-nav {
  background-color: #fff;
  box-shadow: 2px 3px 30px #ccc;
}
.fixed-nav .nav-link a {
  color: var(--main-color);
}
.fixed-nav .top-hr,
.fixed-nav .bottom-hr {
  background-color: var(--main-color);
}
img {
  object-fit: cover;
  display: block;
}
.hero-section {
  width: 100vw;
  height: 100%;
  position: relative;
  margin-bottom: 100px;
}
.hero-section > .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  height: 95vh;
  background-color: #fff;
}
.container {
  margin: 0 auto;
  max-width: var(--max-width);
}
.gradient {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: linear-gradient(
    to right,
    rgba(6, 6, 20, 0.4),
    rgba(252, 252, 252, 0.4)
  ); */
}
.hero-section .img-container img {
  width: 100%;
  height: 100%;
}
.hero-input {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 300px;
  padding: 10px 10px;
}
.hero-input input {
  border-radius: 10px;
  width: 100%;
  padding: 5px;
  border: 0;
  background-color: transparent;
  outline: none;
}
.hero-input svg {
  color: #ccc;
  font-size: 1.5rem;
}
.hero-contents {
  margin-top: 30px;
  margin-bottom: 60px;
}
/* .book-section {
  margin-top: 150px;
} */

/* destinations */
.destinations-info {
  margin: 0 auto;
  max-width: var(--max-width);
}
.destinations {
  padding: 0 30px;
}
.destinations h2 {
  margin: 0 auto;
  max-width: var(--max-width);
  font-size: 2rem;
}
.destinations-container > h4 {
  color: #5e616f;
  margin: 0 auto;
  margin-top: 10px;
  max-width: var(--max-width);
  margin-bottom: 50px;
}
.title {
  width: 100%;
  padding-left: 40px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 60px;
}
.title h4 {
  margin: 0 auto;
  max-width: var(--max-width);
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 0;
  color: var(--gradient-blue);
}
.title h4::after {
  content: "";
  width: 65px;
  height: 3px;
  border: 0;
  border-radius: 3px;
  background-color: var(--border-color);
  position: absolute;
  left: 0;
  bottom: 0;
}
.destinations-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 70px;
}
.destinations-info h4 {
  color: #555;
}
.destinations-info p {
  color: var(--gradient-blue);
  margin: 5px 0;
}
.container h2,
.container h4 {
  text-transform: capitalize;
  text-align: center;
}
.container h2 {
  font-size: 2.5rem;
  color: #1a1919;
  font-weight: 900;
}
.container h4 {
  color: #555;
  margin-top: 0;
}
.container button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  border: 0;
  margin: 50px auto;
  background-color: var(--border-color);
}
button a {
  color: #fff;
  text-transform: capitalize;
}

/* Booking */
.booking-process {
  background: #f1f1f1;
}
.booking-process h2 {
  text-align: start;
  margin-bottom: 5px;
}
.booking-process h4 {
  text-align: center;
  margin-top: 10px;
  font-weight: 700;
}

.bookings {
  margin: 30px 0;
}
.bookings p {
  color: #5e616f;
  font-size: 0.8rem;
  margin-top: 0;
  line-height: 1.5;
}

.bookings h3 {
  text-transform: capitalize;
  margin-bottom: 10px;
}
.bookings span {
  background: linear-gradient(
    to right,
    rgba(255, 250, 241, 0.6),
    rgba(202, 163, 71, 0.6)
  );

  margin-right: 10px;
  border-radius: 5px;
  padding: 3px 8px;
  color: #fff;
  font-weight: 500;
}
.single-booking {
  min-width: 250px;
  min-height: 350px;
  display: flex;
}

.single-booking:nth-child(2) {
  flex-direction: row-reverse;
}

.booking-img {
  flex: 1;
}

.booking-description {
  width: 50%;
}

.bookings img {
  /* height: 150px; */
  /* margin-bottom: 30px; */
  width: 100%;
  object-fit: contain;
  /* border-radius: 20px; */
}
.video-section {
  position: relative;
  height: 500px;
  margin: 150px 0;
}
.video-section h2 {
  color: #fff;
}
.video-section img {
  height: 100%;
  width: 100%;
  border-radius: 30px;
}
.video-info {
  position: absolute;
  padding: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-info h2,
.video-info h4 {
  text-align: start;
  color: #fff;
}
.video-info h2 {
  margin-bottom: 10px;
}
/* .video-section svg {
  font-size: 5rem;
  color: #fff;
  margin-top: 150px;
  margin-right: 100px;
} */
.video-info button {
  margin: 0;
  color: #fff;
  text-transform: capitalize;
}
.video-info div:first-child {
  width: 500px;
  margin-right: auto;
}
.single-adventure img {
  height: 100%;
  width: 100%;
  border-radius: 30px;
}
.single-adventure {
  height: 300px;
  width: 100%;
  position: relative;
}
.adventures {
  box-sizing: border-box;
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  gap: 30px;
  padding-top: 2%;
  padding-bottom: 10%;
}
.single-info {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.single-info h5 {
  text-transform: capitalize;
  font-size: 1rem;
}
.single-info p {
  display: flex;
  align-items: center;

  column-gap: 10px;
}
.adventures div:first-child {
  grid-column: 1/3;
}
.adventures div:nth-child(2) {
  grid-column: 1/2;
  grid-row: 2/3;
}
.adventures div:nth-child(3) {
  grid-column: 2/3;
  grid-row: 2/3;
}
/* Transfer */
.single-transport-section {
  /* padding: 0 30px; */
}
.transfer-vehicles {
  margin: 0 auto;
  max-width: 1000px;
  margin-top: 10px;
}
.transfer-vehicles .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #fff;
}
.vehicle-content {
  padding: 20px 20px;
  border-radius: 10px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  column-gap: 30px;
}
.vehicle-content img {
  width: 200px;
}
.vehicle-content .info h5 {
  font-size: 1.1rem;
  margin: 0;
}
.vehicle-content .info p {
  margin: 5px 0;
}
.learn,
.vehicle-content span {
  color: var(--border-color);
  font-size: 0.8rem;
}
.learn {
  font-size: 0.95rem;
}
.vehicle-content .info ul li {
  margin: 6px 0;
  font-size: 0.9rem;
}
.vehicle-content li svg {
  margin-right: 10px;
  color: var(--main-color);
}
.vehicle-content .small {
  font-size: 0.75rem;
  color: var(--border-color);
  margin-bottom: 15px;
}
.btn {
  background-color: #175af5;
  border-radius: 7px;
  color: #fff;
  width: 100%;
  border: 0;
  margin: 20px 0;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 0.95rem;
}

.other-vehicles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 30px;
  justify-content: center;
  margin-bottom: 100px;
}
.other-vehicles .vehicle-content {
  display: block;
  padding: 20px 25px;
  border-radius: 10px;
  row-gap: 20px;
  box-shadow: 1px 1px 30px 2px #cecccc;
}
.other-vehicles img {
  margin: 10px auto;
}
.other-vehicles .info > h4 {
  margin: 7px 0;
}
.bcg {
  height: 122vh;
  z-index: -1;
  width: 100vw;
  background-color: #cccccc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.transfer-vehicles h2 {
  margin: 40px 0;
  font-weight: 300;
}

.user-bx {
  background: white;
  border-radius: 15px;
  padding: 35px;
  margin: 80px auto 0;
  max-width: var(--max-width);
}

.input-bx {
  display: flex;
  justify-content: space-between;
}

.input-bx p {
  font-weight: 300;
  font-size: 16px;
  margin: 0;
}

.input-bx h4 {
  font-size: 20px;
}

.input-bx button {
  width: 20%;
  background-color: #4e7ff1;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input-field {
  display: flex;
  gap: 20px;
}

.input-field input[type="radio"] {
  width: 20px;
  height: 20px;
}

.input-text,
.input-field input[type="email"],
.input-field input[type="time"],
.input-field input[type="tel"] {
  width: 100%;
  padding: 10px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-container {
  /* max-width: 400px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 20px 40px;
  border-radius: 15px;
  margin: auto;
}

.form-container h2 {
  text-transform: capitalize;
  font-size: 3rem;
  margin-bottom: 25px !important ;
  font-weight: bold;
  border-bottom: 1px dotted;
  width: max-content;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 0.2rem;
}

.form-container button {
  width: 20%;
  background-color: #4e7ff1 !important ;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* input-location */

.location-search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.autocomplete-dropdown-container {
  position: relative;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: lightgray;
  padding: 10px;
  cursor: pointer;
  /* font-size: 20px; */
}

.autocomplete-dropdown-container {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  border-bottom: none !important;
  border-top: none !important;
  border: 1px solid #ccc;
  position: absolute;
  width: 20.5%;
}

@media (max-width: 937px) {
  .single-booking {
    box-shadow: 1px 20px 20px 1px #e7e6e6;
  }
  .bookings {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
  }

  .vehicle-content {
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
    max-width: 700px;
  }
  .vehicle-content img {
    margin: 10px auto;
  }
  .other-vehicles {
    align-items: center;
    grid-template-columns: 1fr;
  }
  .other-vehicles .vehicle-content {
    justify-self: center;

    width: 100%;
    max-width: 700px;
  }
}

@media (max-width: 1166px) {
  .hero-contents {
    margin-left: 30px;
  }

  .book-section {
    padding: 0 30px;
  }
}
@media (max-width: 937px) {
  .book-form {
    flex-direction: column;
  }
  .from-cont {
    width: 100%;
  }

  .hero-contents {
    max-width: 600px;
    margin: 100px auto;
  }

  .hero-contents h1 {
    font-size: 1.2rem;
    font-weight: 800;
    padding-right: 30px;
    text-align: center;
  }
  .hero-contents h4 {
    font-size: 1rem;
  }
}
@media (max-width: 662px) {
  .nav-links {
    padding: 0;
  }
  .nav-link {
    display: none;
  }
  .hero-contents {
    padding: 0 20px;
  }
}
@media (max-width: 375px) {
  .hero-contents {
    width: 250px;
  }
}

@media (max-width: 550px) {
  .autocomplete-dropdown-container {
    width: fit-content;
  }
  .input-field,
  .input-bx {
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
    width: 100%;
  }
  .transfer-vehicles {
    margin: 0 30px;
  }
}

/* Page vehicules */

.vehicle-cards {
  display: flex;
  align-items: center;
}

.vehicle-content img {
  height: 130px;
}

button.sliders-btn {
  height: 100%;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliders-btn-left {
  left: 14px;
  position: relative;
  /* background: linear-gradient(to right,black,orange); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background-color: lightgray;
}

.sliders-btn-right {
  /* background: linear-gradient(to left,black,orange); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background-color: lightgray;
  position: relative;
  right: 14px;
}

.info .date-btn {
  background: #175af5;
  color: #fff;
}

.info .date-btn:disabled {
  background: gray;
  border: none;
  color: #fff;
}
