.electric-car {
    font-size: 65px;
}

.diamond {
    font-size: 65px;
}

.star {
    font-size: 65px;
}

.calendar {
    font-size: 165px;
}

.route {
    font-size: 165px;
}

.network {
    font-size: 165px;
}